import React from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import Footer from "./pages/Footer";
import Header from "./pages/Header";
import Home from "./pages/Home";
import PrivacyPolicy from "./pages/PrivacyPolicy";

type Props = {};
type State = {}

class App extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    render() {
        return (
            <>
                <Header/>
                <div className="page-wrapper">
                    <div className="page-titles2">
                    </div>
                    <BrowserRouter>
                        <Routes>
                            <Route path="/" element={<Home/>}/>
                            <Route path="/home" element={<Home/>}/>
                            <Route path="/privacy-policy" element={<PrivacyPolicy/>}/>
                        </Routes>
                    </BrowserRouter>
                    <Footer/>
                </div>
            </>
        );
    }
}

export default App;
