import React from "react";
import ISetting from "../types/ISetting";

type Props = {};

type State = {
    settings: ISetting
}

export default class HomeAboutUs extends React.Component<Props, State> {

    constructor(props: any) {
        super(props);
        this.state = {
            settings: {} as ISetting
        };
    }

    componentDidMount(): void {
        this.getData();
    }

    getData = () => {
        fetch('./assets/data/settings.json')
            .then(res => {
                return res.json();
            })
            .then(result => {
                    this.setState({
                        settings: result
                    })
                }
            );
    };

    render() {
        const {settings} = this.state
        return (
            <>
                <section className="about-us" id="about-us">
                    <div className="overlay pt-120 pb-120">
                        <div className="container wow fadeInUp">
                            <div className="row justify-content-center">
                                <div className="col-lg-10">
                                    <div className="section-header text-center">
                                        <h5 className="sub-title">想象、创新、化身</h5>
                                        <h2 className="title">我们的使命是创造伟大的娱乐世界</h2>
                                        <p>我们的使命是娱乐世界，而不仅仅是游戏</p>
                                    </div>
                                </div>
                            </div>
                            {/*<div className="row">
                                <div className="col-lg-12">
                                    <div
                                        className="magnific-area position-relative d-flex align-items-center justify-content-around">
                                        <div className="bg-area">
                                            <img className="bg-item" src="assets/data/video-bg.png" alt="image"/>
                                        </div>
                                        <div
                                            className="content-area position-absolute d-flex align-items-center justify-content-center">
                                            <div className="content-box">
                                                <a className="mfp-iframe popupvideo d-flex align-items-center justify-content-center"
                                                   href="https://www.youtube.com/watch?v=4XWBd1Jnpkw">
                                                    <img src="assets/images/icon/play-icon.png" alt="icon"/>
                                                </a>
                                                <h2>见面 BBOLD Global Limited</h2>
                                                <p>我们年轻但大胆</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>*/}
                        </div>
                    </div>
                </section>
                <section className="counter-section">
                    <div className="overlay pb-120">
                        <div className="container wow fadeInUp">
                            <div className="row justify-content-center align-items-center">
                                <div className="col-lg-6">
                                    <div className="section-area">
                                        <h5 className="sub-title">BBOLD Global Limited</h5>
                                        <h2 className="title">Game Studio 年轻而充满活力</h2>
                                        <p>我们对游戏和制作游戏充满热情！我们为日常生活打造一流的娱乐和创意应用程序。</p>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="row cus-mar">
                                        <div className="col-sm-6 mt-60">
                                            <div className="single-box">
                                                <h1><span>{settings.games_launched}</span></h1>
                                                <p>游戏</p>
                                            </div>
                                            <div className="single-box second">
                                                <h1><span>{settings.MAU}</span></h1>
                                                <p>月活跃用户</p>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="single-box third">
                                                <h1><span>{settings.downloads}</span></h1>
                                                <p>下载</p>
                                            </div>
                                            <div className="single-box fourth">
                                                <h1><span>{settings.members}</span></h1>
                                                <p>成员</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}