import React from "react";

export default class PrivacyPolicy extends React.Component {

    render() {
        return (
            <section className="banner-section index" id="index">
                <div className="overlay">
                    <div className="banner-content">
                        <div className="container wow fadeInUp">
                            <div className="row justify-content-between align-items-center">
                                <div className="col-xl-12 col-lg-12 col-md-12">
                                    <div className="main-content">
                                        <div className="calibre" id="calibre_link-0">
                                            <p className="block_" lang="en"><h3>PRIVACY POLICY</h3></p>
                                            <p className="block_1" lang="en">Effective Date: June 01, 2021</p>
                                            <p className="block_2">&nbsp;</p>
                                            <p className="block_1" lang="en">Thank you for playing our games! This
                                                Privacy Policy describes:</p>
                                            <ul className="list-group-bullet">
                                                <li>What Personal Data we collect from you,</li>
                                                <li>The ways we collect your Personal Data and why we do so,</li>
                                                <li>How we process your Personal Data, and</li>
                                                <li>The choices you have regarding your Personal Data.</li>
                                            </ul>
                                            <p className="block_2">&nbsp;</p>
                                            <p className="block_1" lang="en">This Privacy Policy applies to BBOLD Global
                                                Studio
                                                games, websites and
                                                related services, which we here collectively call the “App”. We may
                                                periodically update this
                                                Privacy Policy by posting a relevant notification at home page of the
                                                App. Your continued use of
                                                the App after the effective date of the updated Privacy Policy will be
                                                subject to the new
                                                Privacy Policy. If we make any major changes to our Privacy Policy and
                                                will need your explicit
                                                consent for further processing of your Personal Data, we will request
                                                your consent or your
                                                renewed consent (in case it was obtained previously).</p>
                                            <p className="block_2">&nbsp;</p>
                                            <p className="block_4" lang="en">If you do not agree with this Privacy
                                                Policy, PLEASE do not
                                                download or use our APP.</p>
                                            <p className="block_2">&nbsp;</p>
                                            <p className="block_5" lang="en"><h5>CONTACT US</h5></p>
                                            <p className="block_2">&nbsp;</p>
                                            <p className="block_1"><span lang="en" className="calibre1">If you have questions regarding your Personal Data, we encourage you to contact us by e-mail at </span>
                                                <a className="ext-link"
                                                   href="mailto:dreamer@bboldglobal.com">dreamer@bboldglobal.com</a>.
                                            </p>
                                            <p className="block_2">&nbsp;</p>
                                            <p className="block_1" lang="en">BBOLD Global Studio is a controller and is
                                                responsible for collection and
                                                processing of your Personal Data (collectively referred to as “we”, “us”
                                                or “our”).</p>
                                            <p className="block_2">&nbsp;</p>
                                            <p className="block_1" lang="en">BBOLD Global Studio</p>
                                            <p className="block_1" lang="en">Address: KOWLOON - HongKong</p>
                                            <p className="block_1" lang="en">e-mail: dreamer@bboldglobal.com</p>
                                            <p className="block_2">&nbsp;</p>
                                            <p className="block_5" lang="en"><h5>THE DATA WE COLLECT</h5></p>
                                            <p className="block_2">&nbsp;</p>
                                            <p className="block_1" lang="en">When you use our App we collect information
                                                about you that can
                                                directly or indirectly identify you (“Personal Data”):</p>
                                            <p className="block_2">&nbsp;</p>
                                            <p className="block_5" lang="en">We receive Personal Data you provide us
                                                yourself, which
                                                includes:</p>
                                            <ul className="list-group-bullet">
                                                <li className="block_3"><span lang="en" className="calibre1">Contact Data (name and e-mail address) and other data that you provide to us.</span>
                                                </li>
                                            </ul>
                                            <p className="block_2">&nbsp;</p>
                                            <p className="block_1" lang="en">We process your Contact Data if you provide
                                                it to us when contact
                                                us on technical support issues, leaving feedback, etc.</p>
                                            <ul className="list-group-bullet">
                                                <li className="block_3"><span lang="en" className="text_1">Profile Data (nickname and profile photo). If you </span><span
                                                    lang="en" className="calibre1">log into the App using third-party site or platform we can also collect other Personal Data as described in the section “Data we collect from our partners”.</span>
                                                </li>
                                            </ul>
                                            <p className="block_2">&nbsp;</p>
                                            <p className="block_5" lang="en">The following groups of Personal Data are
                                                collected
                                                automatically:</p>
                                            <ul className="list-group-bullet">
                                                <li className="block_3"><span lang="en" className="calibre1">Technical Data (your IP address and mobile device identifiers (such as your device ID, advertising ID, MAC address, IMEI, IDFA), device name and operating system, browser type and language, general location data, precise geo-location data (GPS, with your consent)),</span>
                                                </li>
                                                <li className="block_3"><span lang="en" className="calibre1">Usage Data (information about your use of the App, such as gameplay data and your interactions with other users inside the App, game progress, your in-app purchases). We also can create a Kurshi-specific ID for you when you use the App.</span>
                                                </li>
                                            </ul>
                                            <p className="block_1" lang="en">Technical Data is collected with the help
                                                of cookies. More
                                                information on how we use cookies can be found in the section “Cookies
                                                and similar
                                                technologies”.</p>
                                            <p className="block_2">&nbsp;</p>
                                            <p className="block_5" lang="en"><h6>Data we collect from our partners.</h6>
                                            </p>
                                            <p className="block_6" lang="en">If you log into the App using a third-party
                                                site or platform such
                                                as Facebook, Apple Game Center and Google Sign-In, we access your
                                                Personal Data from that site
                                                or platform, such as your screen name, profile information and friend
                                                lists, in accordance with
                                                the privacy policy of such third-party site or platform.</p>
                                            <p className="block_7">&nbsp;</p>
                                            <p className="block_6" lang="en">You can learn more about how such
                                                third-party sites or platforms
                                                process your Personal Data in the relevant privacy policy:</p>
                                            <ul className="list-group-bullet">
                                                <li className="block_8"><span lang="en"
                                                                              className="calibre1">Facebook:&nbsp;</span><a
                                                    lang="en"
                                                    target="_blank"
                                                    href="https://www.facebook.com/about/privacy/"
                                                    className="ext-link">https://www.facebook.com/about/privacy</a>
                                                </li>
                                                <li className="block_8"><span lang="en" className="calibre1">Game Center:&nbsp;</span><a
                                                    lang="en" target="_blank"
                                                    href="https://www.apple.com/legal/internet-services/itunes/gamecenter/"
                                                    className="ext-link">https://www.apple.com/legal/internet-services/itunes/gamecenter</a>
                                                </li>
                                                <li className="block_8"><span lang="en"
                                                                              className="text_3">Google Play&nbsp;Games</span><span
                                                    lang="en" className="calibre1"> </span><a lang="en"
                                                                                              href="https://policies.google.com/privacy"
                                                                                              className="ext-link">https://policies.google.com/privacy</a>
                                                </li>
                                            </ul>
                                            <p className="block_7">&nbsp;</p>
                                            <p className="block_6" lang="en">If you log into the App using a third party
                                                site or platform, you
                                                represent and warrant that (i) your access and use of such features in
                                                connection with the App
                                                will comply with the applicable terms and policies of such site or
                                                platform; and (ii) that you
                                                are over the minimum age limit that is prescribed for such third party
                                                site or platform by the
                                                legislation in the applicable jurisdiction.</p>
                                            <p className="block_7">&nbsp;</p>
                                            <p className="block_9" lang="en"><h6>Data storage.</h6></p>
                                            <p className="block_1" lang="en">We respect the importance of your Personal
                                                Data, so we make every
                                                effort to store your Personal Data in a format that cannot be linked to
                                                a particular user. We
                                                keep your Personal Data related to activities within the App separate
                                                from your Contact Data
                                                (until you log into the App using a third-party site or platform, after
                                                which your Personal Data
                                                related to activities within the App is linked to your Personal Data
                                                received from relevant
                                                third-party site or platform in accordance with their privacy
                                                policy).</p>
                                            <p className="block_2">&nbsp;</p>
                                            <p className="block_5" lang="en"><h5>WHY DO WE COLLECT YOUR PERSONAL
                                                DATA</h5></p>
                                            <p className="block_2">&nbsp;</p>
                                            <p className="block_5" lang="en">To provide access to the App.</p>
                                            <p className="block_1" lang="en">To perform the contract concluded with you,
                                                we process your
                                                Personal Data as follows:</p>
                                            <ul className="list-group-bullet">
                                                <li className="block_3"><span lang="en"
                                                                              className="calibre1">Give you correct access to the App,</span>
                                                </li>
                                                <li className="block_3"><span lang="en"
                                                                              className="calibre1">Create account within the App,</span>
                                                </li>
                                                <li className="block_3"><span lang="en" className="calibre1">Provide and deliver products and services you request within the App,</span>
                                                </li>
                                            </ul>
                                            <ul className="list-group-bullet">
                                                <li className="block_3"><span lang="en" className="calibre1">Send you App-related information, such as updates, security alerts, and support messages,</span>
                                                </li>
                                                <li className="block_3"><span lang="en" className="calibre1">Enable you to communicate with other users.</span>
                                                </li>
                                            </ul>
                                            <p className="block_2">&nbsp;</p>
                                            <p className="block_1" lang="en">For these purposes we collect and process
                                                your Contact Data,
                                                Profile Data, Usage Data and Technical Data.</p>
                                            <p className="block_2">&nbsp;</p>
                                            <p className="block_5" lang="en">To keep the App safe and fair.</p>
                                            <p className="block_1" lang="en">Ensuring a level playing field in the App
                                                is a top priority for
                                                us.</p>
                                            <p className="block_1" lang="en">In order to keep the App and its social
                                                features safe and fair, to
                                                fight fraud and ensure acceptable use otherwise, we have a legitimate
                                                interest based on the
                                                balance of interests to process necessary Personal Data in order to:</p>
                                            <ul className="list-group-bullet">
                                                <li className="block_3"><span lang="en" className="calibre1">Analyze and monitor use of the App and its social features,</span>
                                                </li>
                                                <li className="block_3"><span lang="en" className="calibre1">Respond to your comments and questions and provide player support,</span>
                                                </li>
                                                <li className="block_3"><span lang="en" className="calibre1">Take action against fraudulent or misbehaving users.</span>
                                                </li>
                                            </ul>
                                            <p className="block_2">&nbsp;</p>
                                            <p className="block_1" lang="en">For these purposes we collect and process
                                                your Contact Data,
                                                Profile Data, Usage Data and Technical Data.</p>
                                            <p className="block_2">&nbsp;</p>
                                            <p className="block_5" lang="en">To make the App more suitable for you.</p>
                                            <p className="block_1" lang="en">To develop and improve the App and player
                                                experience we pursuing
                                                our legitimate interest based on the balance of interests to collect and
                                                process necessary
                                                Personal Data in order to:</p>
                                            <ul className="list-group-bullet">
                                                <li className="block_3"><span lang="en" className="calibre1">Provide social features as part of the App,</span>
                                                </li>
                                                <li className="block_3"><span lang="en" className="calibre1">Analyze your App experience,</span>
                                                </li>
                                                <li className="block_3"><span lang="en" className="calibre1">Send you App-related communications, including our offers and recommendations,</span>
                                                </li>
                                                <li className="block_3"><span lang="en" className="calibre1">With your explicit consent we may process your GPS location to show you users and communities near you.</span>
                                                </li>
                                            </ul>
                                            <p className="block_2">&nbsp;</p>
                                            <p className="block_1" lang="en">For these purposes we collect and process
                                                your Contact Data,
                                                Profile Data, Usage Data and Technical Data.</p>
                                            <p className="block_2">&nbsp;</p>
                                            <p className="block_5" lang="en">To show you personalized
                                                advertisements.</p>
                                            <p className="block_1" lang="en">To show you personalized advertisements in
                                                the App we use your
                                                explicit consent as a basis for processing of your Personal Data
                                                necessary to:</p>
                                            <ul className="list-group-bullet">
                                                <li className="block_3"><span lang="en" className="calibre1">Track the content you access and your online behavior in connection with the App,</span>
                                                </li>
                                                <li className="block_3"><span lang="en" className="calibre1">Deliver, target and improve our advertisements in the App.</span>
                                                </li>
                                            </ul>
                                            <p className="block_2">&nbsp;</p>
                                            <p className="block_1" lang="en">For these purposes we collect and process
                                                your Profile Data, Usage
                                                Data and Technical Data.</p>
                                            <p className="block_7">&nbsp;</p>
                                            <p className="block_6" lang="en">If you want to renew your profile used for
                                                personalized
                                                advertising, you can reset your Mobile Advertising ID (GAID or IDFA) by
                                                yourself by following
                                                means:</p>
                                            <ul className="list-group-bullet">
                                                <li className="block_8"><b lang="en" className="calibre2">IOS</b>: go to
                                                    “Settings” then “Privacy”, then “Advertising” and then “Reset
                                                    Advertising ID” (see demo on&nbsp;<a
                                                        lang="en" href="https://support.apple.com/en-us/HT202074"
                                                        className="text_4">Apple
                                                        Support)</a><span lang="en" className="calibre1">.</span></li>
                                                <li className="block_8"><b lang="en"
                                                                           className="calibre2">Android</b>: go to
                                                    “Settings” then “Google” then in “Ads” then “Reset Advertising ID”
                                                    (see demo on&nbsp;<a
                                                        lang="en"
                                                        href="https://support.google.com/ads/answer/2662922?hl=en"
                                                        className="text_4">Google
                                                        Support)</a><span lang="en" className="calibre1">.</span></li>
                                            </ul>
                                            <p className="block_6"><b lang="en" className="calibre2">Be aware.&nbsp;</b><span
                                                lang="en"
                                                className="calibre1"> By resetting your Mobile Advertising ID you will still receive advertising on your mobile phone, but this will not be “personalized</span><b
                                                lang="en" className="calibre2"> </b><span lang="en"
                                                                                          className="calibre1">advertising” anymore and may not correspond to your interests.</span>
                                            </p>
                                            <p className="block_2">&nbsp;</p>
                                            <p className="block_5" lang="en"><h5>WHO CAN SEE YOUR PERSONAL DATA</h5></p>
                                            <p className="block_2">&nbsp;</p>
                                            <p className="block_1" lang="en">Apart from us, your Personal Data can be
                                                accessed by others in the
                                                following situations:</p>
                                            <p className="block_2">&nbsp;</p>
                                            <p className="block_5" lang="en">Other users.</p>
                                            <p className="block_1" lang="en">Other users may see your in-App progress
                                                and read messages you have
                                                posted if they are intended to be publicly available.</p>
                                            <p className="block_2">&nbsp;</p>
                                            <p className="block_5" lang="en">Our partners.</p>
                                            <p className="block_1" lang="en">We have partners which we attract to
                                                perform services regarding the
                                                App, such as hosting, player support, advertising, analytics and fraud
                                                prevention. These
                                                partners process your Personal Data only at and according to our
                                                instructions.</p>
                                            <p className="block_2">&nbsp;</p>
                                            <p className="block_1" lang="en">We can share your Personal Data with our
                                                partners only if the
                                                purposes listed in this Privacy Policy require so, and only in relevant
                                                amounts. </p>
                                            <p className="block_2">&nbsp;</p>
                                            <p className="block_1" lang="en">The following SDKs are implemented in the
                                                App and through which the
                                                relevant partners receive your Personal Data:</p>
                                            <ul className="list-group-bullet">
                                                <li className="block_3"><span lang="en" className="calibre1">Facebook SDK,</span>
                                                </li>
                                                <li className="block_10"><span lang="en" className="calibre1">Mail.ru SDK.</span>
                                                </li>
                                            </ul>
                                            <p className="block_2">&nbsp;</p>
                                            <p className="block_1" lang="en">We also use IronSourse as an advertising
                                                partner that can display
                                                in-App advertisements.</p>
                                            <p className="block_2">&nbsp;</p>
                                            <p className="block_5" lang="en">Other companies and public authorities.</p>
                                            <p className="block_1" lang="en">We may also disclose your Personal Data to
                                                comply with the law or
                                                to protect the rights, property or safety of us, our users or others.
                                                Particularly, in order to
                                                combat fraud and illegal activity, we may exchange data with other
                                                companies and organizations
                                                and provide it to public authorities in response to lawful requests.</p>
                                            <p className="block_2">&nbsp;</p>
                                            <p className="block_5" lang="en">Social media partners.</p>
                                            <p className="block_1" lang="en">The App may include features from our
                                                partners such as social media
                                                interaction tools (such as the Facebook “Like” button). If you use
                                                features from our partners
                                                they may access your Personal Data collected by us. Please note that
                                                they operate under their
                                                own privacy policies.</p>
                                            <p className="block_2">&nbsp;</p>
                                            <p className="block_5" lang="en">Our corporate affiliates.</p>
                                            <p className="block_1" lang="en">We may share your Personal Data with our
                                                corporate affiliates to be
                                                used in a manner consistent with this Privacy Policy. If we or our
                                                assets acquired or
                                                transferred in part or in whole to another organization, your Personal
                                                Data and any other
                                                information we have collected may be among the items transferred.</p>
                                            <p className="block_2">&nbsp;</p>
                                            <p className="block_5" lang="en"><h5>COOKIES AND SIMILAR TECHNOLOGIES</h5>
                                            </p>
                                            <p className="block_2">&nbsp;</p>
                                            <p className="block_1" lang="en">Like most online services, we and our
                                                partners use cookies and
                                                similar technologies. </p>
                                            <p className="block_1" lang="en">Cookies are small text files sent to your
                                                computer or mobile device
                                                that enable services and website features to function properly. Cookies
                                                make it easier for you
                                                to use the App during future visits. They also allow us to monitor
                                                traffic and to personalize
                                                the content of the App for you.</p>
                                            <p className="block_2">&nbsp;</p>
                                            <p className="block_1" lang="en">Some of our cookies are necessary for
                                                certain uses of the App.
                                                These cookies allow us to make our App usable by enabling basic
                                                functions like page navigation
                                                and access to secure areas of the App. The App cannot function properly
                                                without these
                                                cookies.</p>
                                            <p className="block_1" lang="en">We also have created and implemented our
                                                cookies and cookies from
                                                third parties for analytical and marketing purposes. Analytical and
                                                marketing cookies, among
                                                other things, collect information on how users use our App, the content
                                                and products that users
                                                view most frequently and the effectiveness of our third-party
                                                advertising.</p>
                                            <p className="block_2">&nbsp;</p>
                                            <p className="block_1" lang="en">The list of third-party cookies that we use
                                                is specified in the
                                                section “Our partners”. You can generally activate or later deactivate
                                                the use of cookies
                                                through a functionality built into your web browser or mobile
                                                device. </p>
                                            <p className="block_2">&nbsp;</p>
                                            <p className="block_1" lang="en">To learn more about how to control cookie
                                                settings through your
                                                browser: </p>
                                            <ul className="list-group-bullet">
                                                <li className="block_3"><span lang="en"
                                                                              className="calibre1">Chrome &ndash;</span><b
                                                    lang="en"
                                                    className="calibre2"> </b><a
                                                    lang="en"
                                                    href="https://support.google.com/chrome/answer/95647?hl=en"
                                                    className="text_5">link</a></li>
                                                <li className="block_10"><span lang="en" className="calibre1">Internet Explorer &ndash; </span><a
                                                    lang="en"
                                                    href="https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies"
                                                    className="text_5">&nbsp;link</a>
                                                </li>
                                                <li className="block_10"><span lang="en"
                                                                               className="calibre1">Safari &ndash; </span><a
                                                    lang="en"
                                                    href="https://support.apple.com/kb/PH5042?locale=en_US"
                                                    className="text_5">&nbsp;link</a><span
                                                    lang="en" className="text_4">&nbsp;(desktop) and&nbsp;</span><a
                                                    lang="en"
                                                    href="https://support.apple.com/en-us/HT201265"
                                                    className="text_5">link</a><span
                                                    lang="en" className="text_4"> (mobile)</span></li>
                                                <li className="block_10"><span lang="en"
                                                                               className="calibre1">Firefox &ndash; </span><a
                                                    lang="en"
                                                    href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences"
                                                    className="text_5">&nbsp;link</a></li>
                                            </ul>
                                            <p className="block_1" lang="en">For other browsers, please, consult the
                                                documentation that your
                                                browser manufacturer provides.</p>
                                            <p className="block_2">&nbsp;</p>
                                            <p className="block_11"><span lang="en" className="text_6">On your iPhone, iPad or Android device, you can change your device’s privacy settings to control whether you see online personalized</span><span
                                                lang="en" className="text_7"> </span><span lang="en"
                                                                                           className="text_6">advertisements:</span>
                                            </p>
                                            <ul className="list-group-bullet">
                                                <li className="block_3"><b lang="en" className="calibre2">iOS device</b>:
                                                    Go to your Settings &gt; Select Privacy &gt; Select
                                                    Advertising &gt; Enable the “Limit Ad Tracking” setting.
                                                </li>
                                                <li className="block_10"><b lang="en" className="calibre2">Android
                                                    device</b>: Open your Google Settings app &gt; Ads &gt; Enable “Opt
                                                    out of interest-based advertising”
                                                </li>
                                            </ul>
                                            <p className="block_2">&nbsp;</p>
                                            <p className="block_1" lang="en">You can also contact us via
                                                dreamer@bboldglobal.com with the
                                                request to disable particular categories of cookies.</p>
                                            <p className="block_2">&nbsp;</p>
                                            <p className="block_5" lang="en">International data transfers.</p>
                                            <p className="block_1" lang="en">Our App is global by nature and your
                                                Personal Data can therefore be
                                                transferred to anywhere in the world. Because different countries may
                                                have different data
                                                protection laws than your own country, we take steps to ensure adequate
                                                safeguards are in place
                                                to protect your data as explained in this Privacy Policy. Adequate
                                                safeguards that our partners
                                                may use include standard contractual clauses approved by EU Commission
                                                and the Privacy Shield
                                                certification in case of transfers to the USA.</p>
                                            <p className="block_2">&nbsp;</p>
                                            <p className="block_5" lang="en"><h5>YOUR RIGHTS AND OPTIONS</h5></p>
                                            <p className="block_2">&nbsp;</p>
                                            <p className="block_1" lang="en">We process and answer your requests without
                                                undue delay and in any
                                                event within one month of our receipt of the request unless a longer
                                                period is required due to
                                                the complexity of the request. In this case, our response time can be up
                                                to three months in
                                                total.</p>
                                            <p className="block_2">&nbsp;</p>
                                            <p className="block_1" lang="en">You have the following rights regarding
                                                your Personal Data we hold
                                                about you, unless provided otherwise by applicable law.</p>
                                            <p className="block_2">&nbsp;</p>
                                            <p className="block_5" lang="en">Opt-out of marketing e-mails and other
                                                direct marketing.</p>
                                            <p className="block_1" lang="en">You may opt-out of receiving promotional
                                                communications, such as
                                                marketing e-mails from us by following the instructions in such
                                                communications.</p>
                                            <p className="block_2">&nbsp;</p>
                                            <p className="block_5" lang="en">Access the Personal Data we hold about
                                                you.</p>
                                            <p className="block_1" lang="en">If you request, we will provide you a copy
                                                of your Personal Data in
                                                an electronic format. The request should be provided to via e-mail at
                                                dreamer@bboldglobal.com. You can access the following information
                                                upon your request:</p>
                                            <ul className="list-group-bullet">
                                                <li className="block_3"><span lang="en"
                                                                              className="calibre1">the purposes of the processing;</span>
                                                </li>
                                                <li className="block_3"><span lang="en" className="calibre1">the categories of Personal Data concerned;</span>
                                                </li>
                                                <li className="block_3"><span lang="en" className="calibre1">the recipients or categories of recipient to whom the Personal Data have been or will be disclosed;</span>
                                                </li>
                                                <li className="block_3"><span lang="en" className="calibre1">the planned period for which the Personal Data will be stored.</span>
                                                </li>
                                            </ul>
                                            <p className="block_2">&nbsp;</p>
                                            <p className="block_5" lang="en">Your other rights.</p>
                                            <p className="block_1" lang="en">You also have the right to correct your
                                                Personal Data, have your
                                                Personal Data deleted, object how we process or share your Personal
                                                Data, and restrict how we
                                                process or share your Personal Data. You can at any time send us a
                                                relevant request to correct,
                                                delete, stop or restrict processing or sharing of your Personal Data via
                                                e-mail at
                                                dreamer@bboldglobal.com.</p>
                                            <p className="block_2">&nbsp;</p>
                                            <p className="block_1" lang="en">If your explicit consent serves the basis
                                                for Personal Data
                                                processing, you can withdraw the consent at any time sending a relevant
                                                request via e-mail at
                                                dreamer@bboldglobal.com. You can also withdraw your consent using
                                                your device’s settings, for
                                                example by turning off GPS location sharing in your mobile device
                                                settings.</p>
                                            <p className="block_2">&nbsp;</p>
                                            <p className="block_5" lang="en"><h5>RIGHTS OF CALIFORNIA RESIDENTS</h5></p>
                                            <p className="block_2">&nbsp;</p>
                                            <p className="block_1" lang="en">We endeavor to respond to a verifiable
                                                request within 45 days of
                                                its receipt. If we require more time (up to 90 days), we will inform you
                                                of the reason and
                                                extension period in writing. We will deliver our written response by
                                                e-mail.</p>
                                            <p className="block_2">&nbsp;</p>
                                            <p className="block_5" lang="en">Right to opt out.</p>
                                            <p className="block_1" lang="en">Under the California Consumer Privacy Act
                                                (CCPA) each California
                                                resident can request business stops selling Personal Data to third
                                                parties.</p>
                                            <p className="block_2">&nbsp;</p>
                                            <p className="block_5" lang="en">Do we actually "sell" Personal Data?</p>
                                            <p className="block_1" lang="en">We do not, and will not, provide your
                                                Personal Data in direct
                                                exchange for money. Therefore, in the literal sense, we do not sell your
                                                Personal Data. However,
                                                we disclose some categories of Personal Data we collect to the third
                                                parties as explained in the
                                                section “Who can see your Personal Data”.</p>
                                            <p className="block_2">&nbsp;</p>
                                            <p className="block_1" lang="en">To the extent this practice is interpreted
                                                to constitute a “sale”
                                                under the CCPA, please contact us at dreamer@bboldglobal.com to
                                                exercise your right to
                                                withdraw your consent on sharing your Personal Data with third
                                                parties.</p>
                                            <p className="block_2">&nbsp;</p>
                                            <p className="block_5" lang="en">Other rights.</p>
                                            <p className="block_1" lang="en">You also have a right to be informed about
                                                what categories of
                                                Personal Data we are collecting: you can request us to disclose what
                                                Personal Data we have
                                                collected in the past 12 months and right to get a free copy of your
                                                Personal Data disclosed in
                                                a readily usable and readable format. You can also request us to delete
                                                the Personal Data we
                                                have collected in the past 12 months. We will not discriminate against
                                                you for exercising any of
                                                your rights granted under CCPA.</p>
                                            <p className="block_2">&nbsp;</p>
                                            <p className="block_7"><span lang="en" className="text_8">Mandatory verification.</span><span
                                                lang="en" className="text_9"> </span></p>
                                            <p className="block_6"><span lang="en" className="text_3">We will need to verify your identity before processing your request. In order to verify your identity, we will generally either require the successful </span><span
                                                lang="en" className="calibre1">log into your </span><span lang="en"
                                                                                                          className="text_3">account in the App or the matching of sufficient information you provide us to the information we maintain about you in our systems. </span><span
                                                lang="en" className="calibre1">In certain circumstances, we may decline the request, mainly where we are unable to verify your identity, for example, if you do not have an account in the App and have disable cookies, changed the device you used to access the App or you already requested us to delete your Personal Data.</span>
                                            </p>
                                            <p className="block_7">&nbsp;</p>
                                            <p className="block_5" lang="en"><h5>HOW DO WE PROTECT YOUR PERSONAL DATA</h5></p>
                                            <p className="block_2">&nbsp;</p>
                                            <p className="block_5" lang="en">Security Safeguards.</p>
                                            <p className="block_1" lang="en">In order to ensure a secure and safe player
                                                experience, we are
                                                continuously developing and implementing administrative, technical and
                                                physical security
                                                measures to protect your Personal Data from unauthorized access or
                                                against loss, misuse or
                                                alteration.</p>
                                            <p className="block_2">&nbsp;</p>
                                            <p className="block_5" lang="en">Data retention.</p>
                                            <p className="block_1" lang="en">We retain your Personal Data for as long as
                                                it is necessary to
                                                fulfill the purposes specified in the section “Why do we collect your
                                                Personal Data”. We
                                                regularly (at least once a year) review and de-identify unnecessary data
                                                to store it for
                                                research or statistical purposes only.</p>
                                            <p className="block_1" lang="en">Note that if you ask us to delete your
                                                Personal Data, we can retain
                                                your Personal Data as necessary to comply with our legal obligations or
                                                resolve disputes.</p>
                                            <p className="block_2">&nbsp;</p>
                                            <p className="block_5" lang="en"><h5>AGE LIMITS</h5></p>
                                            <p className="block_2">&nbsp;</p>
                                            <p className="block_1"><span lang="en"
                                                                         className="calibre1">We do not knowingly collect or solicit </span><span
                                                lang="en" className="calibre1">Personal Data</span><span lang="en"
                                                                                                         className="calibre1"> about or direct or target </span><span
                                                lang="en" className="calibre1">personalized advertisements</span><span
                                                lang="en"
                                                className="calibre1"> to anyone under the age of 13</span>, except in European Economic Area (EEA), where the age is limited to users under 16,<span
                                                lang="en" className="calibre1"> or knowingly allow such persons to use our </span><span
                                                lang="en" className="calibre1">App</span><span lang="en"
                                                                                               className="calibre1">.</span><span
                                                lang="en" className="calibre1">&nbsp;If you are under </span><span
                                                lang="en"
                                                className="calibre1">the age of </span><span
                                                lang="en" className="calibre1">13</span><span lang="en"
                                                                                              className="calibre1"> </span><span
                                                lang="en" className="calibre1">(</span><span lang="en"
                                                                                             className="calibre1">or 16</span><span
                                                lang="en" className="calibre1"> </span><span lang="en"
                                                                                             className="calibre1">if you live in EEA territory</span><span
                                                lang="en" className="calibre1">)</span><span lang="en"
                                                                                             className="calibre1">, </span><span
                                                lang="en" className="calibre1">please do not send any</span><span
                                                lang="en"
                                                className="calibre1"> Personal D</span><span
                                                lang="en" className="calibre1">ata </span><span lang="en"
                                                                                                className="calibre1">to us. </span><span
                                                lang="en"
                                                className="calibre1">If we learn that we have collected </span><span
                                                lang="en"
                                                className="calibre1">Personal Data</span><span
                                                lang="en" className="calibre1"> about a child under age 13</span><span
                                                lang="en"
                                                className="calibre1"> (</span><span
                                                lang="en" className="calibre1">or </span><span lang="en"
                                                                                               className="calibre1">16 if applicable), </span><span
                                                lang="en" className="calibre1">we will delete that </span><span
                                                lang="en" className="calibre1">Personal Data</span><span
                                                lang="en" className="calibre1"> as quickly as possible.</span><span
                                                lang="en"
                                                className="calibre1">&nbsp;</span><span
                                                lang="en"
                                                className="calibre1">If you believe that we might have any </span><span
                                                lang="en"
                                                className="calibre1">Personal Data</span><span
                                                lang="en" className="calibre1"> from or about a child under the age of 13</span><span
                                                lang="en"
                                                className="calibre1"> (</span><span
                                                lang="en" className="calibre1">or </span><span lang="en"
                                                                                               className="calibre1">16 if applicable)</span><span
                                                lang="en" className="calibre1">, please contact us</span><span lang="en"
                                                                                                               className="calibre1"> at </span><span
                                                lang="en" className="calibre1">dreamer@bboldglobal.com</span><span
                                                lang="en"
                                                className="calibre1">.</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}