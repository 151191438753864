import React from "react";

export default class HomeBanner extends React.Component {

    render() {
        return (
            <section className="banner-section index" id="index">
                <div className="overlay">
                    <div className="banner-content">
                        <div className="container wow fadeInUp">
                            <div className="row justify-content-between align-items-center">
                                <div className="col-xl-7 col-lg-6 col-md-8">
                                    <div className="main-content">
                                        <div className="top-area section-text">
                                            <h5 className="sub-title">欢迎来到 BBOLD Global Limited</h5>
                                            <h1 className="title">我们年轻但大胆</h1>
                                            <p className="xlr">我们让数百万用户通过我们的游戏获得乐趣</p>
                                            <div className="btn-area mt-30">
                                                <a href="#games" className="cmn-btn">
                                                    <i className="icon-b-right-arrow"></i>
                                                    搜索
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-5 col-lg-6 col-md-8">
                                    <div className="sec-img">
                                        <img src="assets/images/index-illus.png" className="max-un" alt="image"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}